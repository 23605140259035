import NavbarButton1 from "../Navbar/NavbarButton1";
import NavbarButton2 from "../Navbar/NavbarButton2";
import NavbarButton3 from "../Navbar/NavbarButton3";
import NavbarButton4 from "../Navbar/NavbarButton4";

const ModuleNavbar = () => {
  return (
    <div className="navbar">
      <NavbarButton1 />
      <NavbarButton2 />
      <NavbarButton3 />
      <NavbarButton4 />
    </div>
  );
};

export default ModuleNavbar;
