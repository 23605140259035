import React from "react";

function MediaPage() {
  return (
    <div className="middle">
      <h1>Made by horn players</h1>
      <h1>For horn players</h1>
    </div>
  );
}

export default MediaPage;
