const NavbarButton1 = () => {
  return (
    <div className="navbarbutton">
      <a href="./home">
        <img
          src={require("../favicons/favicon.svg")}
          height={"32px"}
          alt="Cor Sound Logo"
        />
      </a>
    </div>
  );
};

export default NavbarButton1;
